$(function(){
	$(function(){
    
    //--------------------------------------------------
    //modal_youtube
    //--------------------------------------------------
    var mordal_flag = false;
    var movId;
    
    //kettles_sp_mov_btn
    $('.mov_paly_btn').on('click', function(){
      movId = $(this).data('videoid');
      if(!mordal_flag){
        mordal_func(mordal_flag,movId);
      }
      return false;
    });
    $('.modal_close,.modal_bg').on('click', function(){
      if(mordal_flag){
        mordal_func(mordal_flag,movId);
      }
      return false;
    });
    
    //mordal_func
    function mordal_func(_mflag,_movid){
      if(_mflag){//close
        videoStop();
        
        $('.modal_body').fadeOut(500);
        var movdisp = function(){
          $('#movie_modal').css({'display':'none'});
        }
        setTimeout(movdisp, 1000);
        mordal_flag = false;
        
      }else{//open
        $('#movie_modal').css('display','block')
        $('.modal_body').fadeIn(800);          
        videoStartFunc(_movid);
        mordal_flag = true;
      }
    }
    
    //-----------
    // IFrame Player API 
    var ytPlayer;
    var loadVideoData = function(){
      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    };
    
    var videoStartFunc = function(_movid){
      //console.log(_movid);
      if(!ytPlayer){
        ytPlayer = new YT.Player('iframe_player_api',{
          host: 'https://www.youtube-nocookie.com',
          width:745,
          height:419,
          videoId:_movid,
          events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
          },
          playerVars: {
            'autoplay': 1,
            'controls': 1,
            'showinfo': 0,
            'rel': 0
          }
        });
      }else{
        ytPlayer.loadVideoById(_movid); 
      }      
    }
    
    function onPlayerReady(event) {
      //event.target.playVideo();
    }
    function onPlayerStateChange(event){
      switch(event.data){
      case YT.PlayerState.ENDED:
        break;
      case YT.PlayerState.PLAYING:
        break;
      case YT.PlayerState.PAUSED:
        break;
      case YT.PlayerState.BUFFERING:
        break;
      case YT.PlayerState.CUED:
        break;
      }
    }
    var videoStart = function(){
      ytPlayer.playVideo();
    }
    var videoStop = function(){
      ytPlayer.stopVideo();
    }
    //-----------
    
    //-----------------------------------
    //load
    //-----------------------------------
    $(window).on('load',function(){
      loadVideoData();
    });
    
  });
});